import React from "react";
import { Youtube } from "../components/Video";
import styled from "styled-components";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

function importAll(r) {
  return r.keys().map(r);
}
const images = importAll(
  require.context("./", false, /\.(png|jpe?g|svg|JPG)$/)
);

export const PageContainer = styled.div`
  /* margin: 30px; */
  display: flex;
  text-align: justify;

  @media only screen and (max-width: 768px) {
    flex-direction: column-reverse;
  }

  .projectBlock {
    margin: 15px;
    width: 50%;
    @media only screen and (max-width: 768px) {
      width: auto;
      /* text-align: initial; */
    }
    a {
      font-weight: bold;
      color: black;
    }
  }
`;

function About() {
  return (
    <PageContainer>
      <div className="projectBlock">
        <p>
          <b>FILO (Fusione di Interni e Lavoro d'Opera)</b>
        </p>
        <p>
          FILO (Fusione di Interni e Lavoro d'Opera) - simbiosi tra quadro e
          divano. È un metodo artistico unico che consiste nel combinare un
          oggetto di uso quotidiano, come un divano, con un quadro attraverso
          l'interazione meccanica e reciproca scambio di parti. Grazie alla
          tecnica FILO un oggetto di uso quotidiano trasforma la sua funzione e
          il suo significato e diventa un opera d'arte, permettendoti di vedere
          le cose ordinarie sotto una nuova luce ed evidenziare il significato
          dell'arte nella vita di tutti i giorni. Noi, Mariia Plekhova e
          Giovanni Stella Grande siamo gli autori di questo metodo. L’arte ora
          incarna anche una funzione pratica. E tutti hanno l'opportunità di far
          entrare l'arte nella propria vita e di farsi un'opera d'arte
          dall'interno.
        </p>
        <p>
          Il nostro metodo è una metafora dell'intreccio tra materiale e
          spirituale, proprio come l'anima e il corpo sono combinati in una
          persona. Le opere realizzate con la tecnica FILO uniscono arte e
          oggetti di uso quotidiano e sono uniche nel loro genere.
        </p>
        <p>
          La nostra attività è finalizzata a creare un ambiente accessibile per
          l'interazione con l'arte e rendere l'arte parte della vita delle
          persone. Come può succedere? Grazie all'interazione costante. E con
          cosa, se non con gli oggetti di uso quotidiano, siamo in costante
          interazione. Grazie alla tecnica FILO da noi sviluppata, i quadri
          entrano in simbiosi con il divano. Così la vita stessa diventa
          un’opera d’arte. Questa è la nostra missione.
        </p>
      </div>
      <div className="projectBlock">
        <div>
          <Youtube
            width="560"
            height="315"
            src="https://www.youtube.com/embed/-s6H74T2GA0?si=NxR355FxF87fJTea"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          />
        </div>
        <Carousel autoPlay={true} infiniteLoop={true} showIndicators={false}>
          {images.map((im) => (
            <img src={im} alt="photo2" key={im} />
          ))}
        </Carousel>
      </div>
    </PageContainer>
  );
}

export default About;
