import { FaBars } from "react-icons/fa";
import { NavLink as Link } from "react-router-dom";
import styled from "styled-components";

export const Nav = styled.nav`
  /* background: #FFFFFF; */
  height: 25px;
  display: flex;
  /* justify-content: space-between; */
  margin: 15px 0 0 0;
  z-index: 12;
  /* position: sticky;
top: 0; */
  bottom: 20px;
  /* Third Nav */
  /* justify-content: flex-start; */
  // position: sticky;
  // top: 0;
  @media screen and (max-width: 768px) {
    /* height: 70px; */
    justify-content: center;
  }
`;

export const Sticky = styled.div`
  background: #ffffff;
  position: sticky;
  top: ${(props) => (props.scrolledDown ? "-7rem" : 0)};
  padding: 10px 0 10px;
  z-index: 100;
  box-shadow: ${(props) =>
    props.scrolledDown
      ? "rgba(27, 31, 35, 0.04) 0px 1px 0px, rgba(255, 255, 255, 0.25) 0px 1px 0px inset"
      : "none"};
  @media screen and (max-width: 768px) {
    padding: 10px 0 40px;
  }
`;

export const NavLink = styled(Link)`
  color: #808080;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 100%;
  cursor: pointer;
  &.active {
    color: #000000;
  }
`;

export const HrefLink = styled.a`
  color: #808080;
  display: flex;
  align-items: center;
  padding: 0 1rem;
  height: 100%;
  text-decoration: underline;
  font-weight: 600;
  cursor: pointer;
  &.active {
    color: #000000;
  }
`;


export const HrefConnector = styled.div`
  color: #808080;
  display: flex;
  align-items: center;
  text-decoration: none;
  font-weight: 600;
  padding: 0 1rem;
  height: 100%;
  cursor: pointer;
  &.active {
    color: #000000;
  }
`;

export const NavHeader = styled(Link)`
  color: #525252;
  display: flex;
  align-items: center;
  font-size: x-large;
  font-weight: 600;
  text-decoration: none;
  padding: 0 1rem;
  height: 100%;
  /* cursor: pointer; */
  &.active {
    /* color: #000000; */
  }
  @media screen and (max-width: 768px) {
    /* font-weight: 600; */
    /* font-size: medium; */
  }
`;

export const Bars = styled(FaBars)`
  display: none;
  color: #808080;
  @media screen and (max-width: 768px) {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 75%);
    font-size: 1.8rem;
    cursor: pointer;
  }
`;

export const NavMenu = styled.div`
  display: flex;
  align-items: center;
  margin-right: -24px;
  /* Second Nav */
  /* margin-right: 24px; */
  /* Third Nav */
  /* width: 100vw;
white-space: nowrap; */
  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const NavBtn = styled.nav`
  display: flex;
  align-items: center;
  margin-right: 24px;
  /* Third Nav */
  /* justify-content: flex-end;
width: 100vw; */
  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const NavBtnLink = styled(Link)`
  border-radius: 4px;
  background: #808080;
  padding: 10px 22px;
  color: #000000;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  /* Second Nav */
  margin-left: 24px;
  &:hover {
    transition: all 0.2s ease-in-out;
    background: #fff;
    color: #808080;
  }
`;
