import React from "react";
import { Nav, NavHeader, HrefLink, HrefConnector } from "./NavbarElements";
import Sidebar from "./SideBar";
import { useMediaQuery } from "@uidotdev/usehooks";

const Navbar = () => {
  const [side, setSide] = React.useState(false);
  const isSmallDevice = useMediaQuery(`only screen and (max-width : 768px)`);
  return (
    <>
      {isSmallDevice && side && (
        <div style={{ zIndex: "30" }}>
          <Sidebar closeBar={() => setSide(false)} />
        </div>
      )}
      <div>
        <Nav>
          <NavHeader to="/">FILO</NavHeader>
        </Nav>
        <Nav>
          <HrefLink target="_blank" href="https://mariiaplekhova.com">Mariia Plekhova</HrefLink>
          <HrefConnector>X</HrefConnector>
          <HrefLink target="_blank" href="https://nicolaquinto.com">Nicolaquinto</HrefLink>
        </Nav>
      </div>
    </>
  );
};

export default Navbar;
